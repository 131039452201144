/* styles.css */

.dialogBox {
    width: 100%;
    height: 100%;
    min-width: 646px;
    min-height: 580px;
    margin: auto;
}

.upgradeHeader {
    font-size: 22px;
    font-weight: 600;
    width: 100%;
}

.subscriptionText {
    font-size: 18px;
    font-weight: 400;
    color: #191A47;
}

.subscriptionAmount {
    font-size: 18px;
    font-weight: 500;
    color: #191A47;
}

.totalContainer {
    border-top: 1px solid #E8E8ED;
    display: flex;
    justify-content: space-between;
}

.totalText {
    font-size: 20px;
    font-weight: 600;
}

.iconBox {
    display: flex;
    width: 128px;
    height: 42px;
    padding: 11px 0;
    justify-content: center;
    align-items: center;
    gap: 28px;
    border-radius: 4px;
    border: 1px solid #E8E8ED;
    background: #F9F9FB;
}

.actions_button .actionButton {
    background: #34aeaa;
    font-size: 19px;
    padding: 15px 0;
    font-weight: 600;
    color: white;
    text-transform: none;
}

.actions_button .actionButton:hover {
    background: #34aeaa;
}