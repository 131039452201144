.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    padding: 0.5rem;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    /* font-size: 32px; */
    padding: 0.5rem;
    text-decoration: none;
    color: #191a47;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    font-size: 46px;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #00BDA4 0%, #00A4BC 100%);
    border-radius: 14px;
    margin: 0 15px;
    max-width: 110px;
    width: 100%;
    padding: 20px 10px;
}
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
    font-size: 46px;
    padding: 0.5rem;
    color: #fff;
    font-weight: 700;
}
  
.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.5rem;
    display: inline-block;
    font-weight: 400;
    padding-top: 5px;
    color: #fff;
    font-size: 15px;
}
  
  .countDown-colon {
    font-size: 24px;
  }